<template>
  <div class="page-login-desktop">

    <Table label="لیست اشتراک ها" icon="mdi-format-list-bulleted" :table="table">
      <tr v-for="(item, index) in table.items" :key="index">
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.name }}</td>
        <td class="text-center">{{ item.description }}</td>
        <td class="text-center">
          {{ item.price }}
          <small>
            تومان
          </small>
        </td>
        <td class="text-center">{{ item.duration }} روز </td>
        <td class="text-center">{{ item.locations }}</td>
        <td class="text-center">
          <EditButton
              @click="item.actions.edit"
          />
        </td>
      </tr>
    </Table>

    <v-dialog width="500" v-model="priceItem.modal">
      <v-card>
        <v-card-title>
          <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
          ویرایش اشتراک
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field hide-details outlined label="نام" v-model="priceItem.subscriptionname" />
            </v-col>

            <v-col :cols="6">
              <v-text-field hide-details outlined label="قیمت" v-model="priceItem.price" />
            </v-col>

            <v-col :cols="6">
              <v-text-field hide-details outlined label="مدت زمان" v-model="priceItem.duration" />
            </v-col>

            <v-col :cols="6">
              <v-text-field hide-details outlined label="تعداد محلات" v-model="priceItem.namesofneighborhoods" />
            </v-col>

            <v-col :cols="12">
              <v-textarea hide-details outlined label="توضیحات" v-model="priceItem.description" />
            </v-col>


          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">

          <v-btn :loading="priceItem.loading" class="white--text" large elevation="0" color="main" @click="update">
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import EditButton from "@/components/EditButton"
import Table from "@/components/Table"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import { updatePrice } from "@Newfiling/module-subscription/src/api";
import moment from "jalali-moment"
import { addCommas, removeCommas } from "@persian-tools/persian-tools";
import { AppConfig, removeAppConfig } from "@Newfiling/Services";
import Vue from 'vue';

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    EditButton,
    NewMessage,
    datePicker: VuePersianDatetimePicker
  },

  data() {
    return {
      table: {
        headers: [
          '#',
          'نام',
          'توضیحات',
          'قیمت',
          'مدت زمان',
          'تعداد محله',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      priceItem: {
        loading: false,
        modal: false,
        id: null,
        subscriptionname: null,
        price: null,
        namesofneighborhoods: null,
        duration: null,
        active: 1,
        description: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست اشتراک ها',
        disabled: true,
        href: '/panel/prices/list',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems() {
      this.table.loading = true;
      try {
        const appConfig = (await AppConfig) || {};
        this.table.items = appConfig.subscribstring.map(item => {
          return {
            id: item.Id,
            description: item.Description,
            duration: item.Duration,
            locations: item.Namesofneighborhoods,
            price: addCommas(item.Price),
            name: item.Subscriptionname,
            actions: {
              edit: () => {
                this.priceItem = {
                  ...this.priceItem,
                  loading: false,
                  modal: true,
                  id: item.Id,
                  subscriptionname: item.Subscriptionname,
                  price: addCommas(item.Price),
                  namesofneighborhoods: item.Namesofneighborhoods,
                  duration: item.Duration,
                  description: item.Description
                }
              }
            }
          }
        });
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async update() {
      this.priceItem.loading = true;
      try {
        await updatePrice({
          ...this.priceItem,
          price: removeCommas(this.priceItem.price)
        });
        this.$toast.success('اشتراک با موفقیت ویرایش شد.');
        await removeAppConfig();
        await this.fetchItems();
        window.location.reload();
      } catch (e) {
        console.log(e)
      }
      this.priceItem.loading = false;
      this.priceItem.modal = false;
    },
  },

  watch: {
    'priceItem.price'(newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.priceItem.price = result);
    },
  }

}
</script>
