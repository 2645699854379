import {httpProvider} from "@Newfiling/Services";

export const updatePrice = (data) => {
    return httpProvider.post('/Admineditsubscriptiontype', data);
};

export const fetchItems = (data) => {
    return httpProvider.post('/AdminSearchforsubscribers2', data);
};

export const update = (data) => {
    return httpProvider.post('/AdminEditNewsubscriptions', data);
};

export const store = (data) => {
    return httpProvider.post('/AdminCreatesubscriptionNewsubscriptions', data);
};

export const fetchChartData = (data) => {
    return httpProvider.post('/Subscriptionchart', data);
};

export const fetchUserHistory = (params) => {
    return httpProvider.post('/customerHistory', {
        params
    });
};

export const fetchSubscriptionHistory = (managermobile) => {
    return httpProvider.post('/Subscriptionhistory', {
        managermobile

    });
};
